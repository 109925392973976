import * as React from 'react';
import CommonLayout from '../templates/CommonLayout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <CommonLayout>
    <Seo title="ページエラー" />
    <p>お探しのページはございません。再度URLをご確認ください。</p>
  </CommonLayout>
);

export default NotFoundPage;
